import React from "react";
import Widget from "./style";
import FluidImage from "../../../../components/FluidImage";
import SbEditable from "storyblok-react";

export default function TestimonialWidget({ blok }) {
  var text = blok.text;
  var name = blok.name;
  var position = blok.subline;
  var reviewStar = parseInt(blok.rating);
  var image = blok.image;

  return (
    <SbEditable content={blok}>
      <Widget>
        {reviewStar && reviewStar <= 5 ? (
          <Widget.Rating pb="15px">
            {Array.from(Array(reviewStar), (_, index) => {
              return (
                <Widget.RatingSingle active={true} key={index + "rws"}>
                  <i className="fa fa-star" />
                </Widget.RatingSingle>
              );
            })}
            {Array.from(Array(5 - reviewStar), (_, index) => {
              return (
                <Widget.RatingSingle key={index + "rws"}>
                  <i className="fa fa-star" />
                </Widget.RatingSingle>
              );
            })}
          </Widget.Rating>
        ) : null}

        <Widget.Text>{text}</Widget.Text>
        <Widget.ProfileBlock mb="40px">
          <Widget.Image>
            <FluidImage blok={image} image={image}></FluidImage>
          </Widget.Image>
          <Widget.UserBlock>
            <Widget.Title as="h3">{name}</Widget.Title>
            <Widget.UserPosition mb="0">{position}</Widget.UserPosition>
          </Widget.UserBlock>
        </Widget.ProfileBlock>
      </Widget>
    </SbEditable>
  );
}
