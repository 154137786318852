import React from "react";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { Images } from "~data";

import TabNavWidget from "./Component/TabNavWidget";
import Feature from "./style";
import SbEditable from "storyblok-react";
import DynamicComponent from "~components/dynamicComponent";

import { graphql } from "gatsby";

import FluidImage from "../../components/FluidImage";

export const FeatureSection = ({ blok }) => {
  return (
    <Feature className="bg-blue-ribbon">
      <Feature.Shapes>
        <FluidImage
          blok={blok}
          image={blok.shape_1}
          className="welcome-image--group-01__main"
          placeholder="blurred"
          layout="fullWidth"
          alt="hero image main"
        />
        <Feature.ShapeTwo>
          <FluidImage
            blok={blok}
            image={blok.shape_2}
            className="welcome-image--group-01__main"
            placeholder="blurred"
            layout="fullWidth"
            alt="hero image main"
          />
        </Feature.ShapeTwo>
      </Feature.Shapes>

      <Container>
        <Row>
          <Col xs="auto" className="col-xl-8 col-lg-10">
            <Feature.Box mb="35px" mbMD="80px" mbLG="148px">
              <SbEditable content={blok}>
                <Feature.Title className="mb-5" as="h2" fontColor="#fff">
                  {blok.title}
                </Feature.Title>
              </SbEditable>
              <SbEditable content={blok}>
                <Feature.Text className="mt-8" fontColor="#fff">
                  {blok.text}
                </Feature.Text>
              </SbEditable>
            </Feature.Box>
          </Col>
        </Row>
        <Tab.Container id="left-tabs-example" defaultActiveKey="0">
          <Feature.Tab>
            <Col className="col-xxl-3 col-lg-3 col-md-12 col-xs-10 col-12">
              <Feature.TabNavWrapper className="nav row ms-0 me-0" as={Nav}>
                {blok.features.map((item, index) => {
                  return (
                    <Nav.Link
                      eventKey={index}
                      className="nav-item col-lg-12 col-md-4 col-xs-6 col-8 me-md-0 me-lg-0"
                    >
                      <TabNavWidget
                        iconClass={`fa ${item.fa_icon_code}`}
                        text={item.title}
                        className="nav-widget"
                        mb="30px"
                        mbLG="50px"
                      />
                    </Nav.Link>
                  );
                })}
              </Feature.TabNavWrapper>
            </Col>
            <Col xs="auto" className="col-xxl-9 col-lg-9 col-md-12 col-sm-12">
              <Tab.Content className="tab-content tab-content--feature">
                {blok.features.map((item, index) => {
                  return (
                    <Tab.Pane eventKey={index}>
                      <Row className="align-items-center">
                        <Col className="col-md-12 col-sm-8 col-xs-9" xs="auto">
                          <DynamicComponent
                            blok={item.item[0]}
                            key={item.item[0]._uid}
                          />
                        </Col>
                      </Row>
                    </Tab.Pane>
                  );
                })}
              </Tab.Content>
            </Col>
          </Feature.Tab>
        </Tab.Container>
      </Container>
    </Feature>
  );
};
