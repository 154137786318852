import React from "react";
import { GatsbyImage as Img } from "gatsby-plugin-image";
import HeroImageGroup from "./style";

import FluidImage from "../../../../components/FluidImage";
import FixedImage from "../../../../components/FixedImage";

export default function ImageGroup({ blok, ...rest }) {
  return (
    <>
      <HeroImageGroup {...rest}>
        <HeroImageGroup.Inner>
          {blok.map((item, index) => {
            if (index == 0) {
              return (
                <FluidImage
                  blok={item}
                  image={item.image}
                  className="welcome-image--group-01__main"
                  placeholder="blurred"
                  layout="fullWidth"
                  alt="hero image main"
                />
              );
            } else {
              return (
                <div className={`welcome-image--group-01__img-${index}`}>
                  <FluidImage
                    blok={item}
                    image={item.image}
                    alt="hero image"
                    layout="fullWidth"
                  />
                </div>
              );
            }
          })}
        </HeroImageGroup.Inner>
      </HeroImageGroup>
    </>
  );
}
