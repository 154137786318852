import React from "react";
import { SuperTag } from "~components";
import Widget from "./style";
import SbEditable from "storyblok-react";

export default function TabContentWidget({ blok, ...rest }) {
  return (
    <SbEditable content={blok}>
      <Widget {...rest}>
        <Widget.Count as="h2">{blok.number}</Widget.Count>
        <Widget.Title as="h5">{blok.title}</Widget.Title>
        <Widget.Text>
          <SuperTag value={blok.text} />
        </Widget.Text>
      </Widget>
    </SbEditable>
  );
}
