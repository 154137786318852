import React from "react";
import app_store from "~assets/custom/app_store.svg";
import play_store from "~assets/custom/play_store.svg";

import styled from 'styled-components'

const StyledDiv = styled.div`
display: flex;
img{
  height: 50px;
  padding-right: 15px;
}

width: 100%;

@media (max-width: 1200px) {
  justify-content: center;

  img{
    height: 45px;
    padding-right: 5px;
    padding-left: 5px;
  
  }
}
`

export default function AppLinkBlock({ blok }) {
  var ios = "https://apple.com"

  if (blok?.ios?.url){
    ios = blok.ios.url;
  }

  var android = "https://google.com"

  if (blok?.android?.url){
    android = blok.android.url;
  }

  return (
    <StyledDiv>
      <a href={ios} target="_blank">
      <img src={app_store}></img>
      </a>
      <a href={android} target="_blank">
      <img src={play_store}></img>
      </a>

    </StyledDiv>
  );
}
