import React from "react";
import { Col } from "react-bootstrap";
import DynamicComponent from "~components/dynamicComponent";

export default function ColBlock({ blok }) {
  return (
    <Col
      xs={blok.xs}
      sm={blok.sm}
      md={blok.md}
      lg={blok.lg}
      xl={blok.xl}
      noGutters={blok.no_gutters}
    >
      {blok.block.map((item, index) => {
        return <DynamicComponent blok={item} key={item._uid} />;
      })}
    </Col>
  );
}
