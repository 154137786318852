import styled from "styled-components/macro";

import { Box } from "~styled";

const StyledSection = styled(Box)`
  background-color: ${(props) => props.bg || "white"};

  padding-top: ${(props) => props.padding_top || "60px"};
  padding-bottom: ${(props) => props.padding_bottom || "20px"};

  @media (min-width: 768px) {
    padding-top: ${(props) => props.padding_top_md || "80px"};
    padding-bottom: ${(props) => props.padding_bottom_md || "40px"};
  }

  @media (min-width: 992px) {
    padding-top: ${(props) => props.padding_top_lg || "100px"};
    padding-bottom: ${(props) => props.padding_bottom_lg || "110px"};
  }
`;

export default StyledSection;
