import React from "react";
import { ButtonLink, ButtonLinkNh, Button } from "~styled";

export default function ButtonBlock({ blok }) {
  var url = ""

  if (blok?.link?.url){
    url = blok.link.url;
  }
  if (!blok.no_hover){
    return (
      <ButtonLink mt={blok.margin_top} mb={blok.margin_bottom} ml={blok.margin_left} mr={blok.margin_right} textColor={blok.color} rounded={blok.rounded} backgroundColor={blok.bg} outline={blok.outline} to={url}>{blok.text}</ButtonLink>
    );
  }else{
    return (
      <ButtonLinkNh mt={blok.margin_top} mb={blok.margin_bottom} ml={blok.margin_left} mr={blok.margin_right} textColor={blok.color} rounded={blok.rounded} backgroundColor={blok.bg} outline={blok.outline} to={url}>{blok.text}</ButtonLinkNh>
    );
  }
}
