import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ImageGroup from "./Components/ImageGroup";
import Content from "./style";
import DynamicComponent from "~components/dynamicComponent";
import SbEditable from "storyblok-react";

export const SplitContent = ({ blok }) => {
  return (
    <>
      <Content backgroundColor="#fff">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col xs="auto" className="col-xl-5 col-lg-5 col-md-6 col-xs-8">
              <SbEditable content={blok.left_block}>
                {blok.left_block.map((item, index) => {
                  return <DynamicComponent blok={item} key={item._uid} />;
                })}
              </SbEditable>
            </Col>
            {/* Content Widgets */}
            <Col
              xs="auto"
              className="col-xxl-4 col-xl-5 offset-xl-1 col-lg-6 offset-lg-1 col-md-8 col-xs-9"
            >
              <SbEditable content={blok.right_block}>
                {blok.right_block.map((item, index) => {
                  return <DynamicComponent blok={item} key={item._uid} />;
                })}
              </SbEditable>
            </Col>
          </Row>
        </Container>
      </Content>
    </>
  );
};
