import React from "react";
import DynamicComponent from "~components/dynamicComponent";
import { Box } from "~styled";
//Test

export default function BoxBlock({ blok }) {
  return (
    <Box
    className={blok.class_name}
    backgroundColor={blok.background_color}
      borderRadius={blok.border_radius}
      borderRadiusMd={blok.md_radius}

      mt={blok.margin_top}
      mb={blok.margin_bottom}
      ml={blok.margin_left}
      mr={blok.margin_right}
      pt={blok.padding_top}
      pb={blok.padding_bottom}
      pl={blok.padding_left}
      pr={blok.padding_right}

      mtXS={blok.margin_top_xs}
      mbXS={blok.margin_bottom_xs}
      mlXS={blok.margin_left_xs}
      mrXS={blok.margin_right_xs}
      ptXS={blok.padding_top_xs}
      pbXS={blok.padding_bottom_xs}
      plXS={blok.padding_left_xs}
      prXS={blok.padding_right_xs}

      mtMD={blok.margin_top_md}
      mbMD={blok.margin_bottom_md}
      mlMD={blok.margin_left_md}
      mrMD={blok.margin_right_md}
      ptMD={blok.padding_top_md}
      pbMD={blok.padding_bottom_md}
      plMD={blok.padding_left_md}
      prMD={blok.padding_right_md}

      mtLG={blok.margin_top_lg}
      mbLG={blok.margin_bottom_lg}
      mlLG={blok.margin_left_lg}
      mrLG={blok.margin_right_lg}
      ptLG={blok.padding_top_lg}
      pbLG={blok.padding_bottom_lg}
      plLG={blok.padding_left_lg}
      prLG={blok.padding_right_lg}

      mtXL={blok.margin_top_xl}
      mbXL={blok.margin_bottom_xl}
      mlXL={blok.margin_left_xl}
      mrXL={blok.margin_right_xl}
      ptXL={blok.padding_top_xl}
      pbXL={blok.padding_bottom_xl}
      plXL={blok.padding_left_xl}
      prXL={blok.padding_right_xl}

      textAlign={blok.alignment}
      textAlignMd={blok.md_alignment}
      textAlignLg={blok.lg_alignment}

    >
      {blok.block.map((item, index) => {
        return <DynamicComponent blok={item} key={item._uid} />;
      })}
    </Box>
  );
}
