import React from "react";
import DynamicComponent from "~components/dynamicComponent";
import StyledSection from "./style";

export default function SectionBlock({ blok }) {
  return (
    <StyledSection
      padding_top={blok.padding_top}
      padding_bottom={blok.padding_bottom}
      padding_top_md={blok.padding_top_md}
      padding_bottom_md={blok.padding_bottom_md}
      padding_top_lg={blok.padding_top_lg}
      padding_bottom_lg={blok.padding_bottom_lg}
      bg={blok.bg}
    >
      {blok.block.map((item, index) => {
        return <DynamicComponent blok={item} key={item._uid} />;
      })}
    </StyledSection>
  );
}
