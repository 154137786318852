import SbEditable from "storyblok-react";
import React from "react";
import { HeroSectionTypingEffect } from "../slices/HeroTypingEffect";
import { ServiceSection } from "../slices/Service";
import { FeatureSection } from "../slices/Features";
import TabContentWidget from "../slices/Features/Component/TabContentWidget";
import { SplitContent } from "../slices/SplitContent";
import TextWithTitle from "../slices/SplitContent/Components/TextWithTitle";
import TestimonialWidget from "../slices/TestimonialBasic/Components/Widget";
import HeroImage from "../slices/HeroImageBanner";
import ContainerBlock from "../slices/Container/Container";
import RowBlock from "../slices/Row/Block";
import ColBlock from "../slices/Col/Block";
import HeroText from "../slices/HeroText/Block";
import Locator from "../slices/Locator/Block";
import SectionBlock from "../slices/Section/Block";
import FluidImage from "../slices/FluidImg/Block";
import TitleBlock from "../slices/Title/Block";
import TextBlock from "../slices/Text/Block";
import BoxBlock from "../slices/Box/Block";
import ButtonBlock from "../slices/Button/Block";
import FlexBlock from "../slices/Flex/Block";
import AccordionBlock from "../slices/Accordion/Block";
import BreakpointBlock from "../slices/Breakpoint/Block";
import MultiSelector from "../slices/MultiTab/Block";
import SliderBlock from "../slices/Slider/Block";
import AppLinkBlock from "../slices/AppLinks/Block";
import ContactForm from "../slices/ContactForm/Block";

const Components = {
  hero_typing_effect: HeroSectionTypingEffect,
  services: ServiceSection,
  features: FeatureSection,
  feature: TabContentWidget,
  split_content: SplitContent,
  text_with_title: TextWithTitle,
  review_item: TestimonialWidget,
  hero_image: HeroImage,
  container: ContainerBlock,
  row: RowBlock,
  col: ColBlock,
  hero_text: HeroText,
  locator: Locator,
  section: SectionBlock,
  fluid_image: FluidImage,
  title: TitleBlock,
  text: TextBlock,
  box: BoxBlock,
  button: ButtonBlock,
  flex: FlexBlock,
  accordion: AccordionBlock,
  breakpoint: BreakpointBlock,
  multi_selector: MultiSelector,
  slider: SliderBlock,
  app_links: AppLinkBlock,
  ContactForm: ContactForm,
};

const DynamicComponent = ({ blok }) => {
  if (typeof Components[blok.component] !== "undefined") {
    const Component = Components[blok.component];
    return (
      <SbEditable content={blok}>
        <Component blok={blok} />
      </SbEditable>
    );
  }
  return (
    <p>
      The component <strong>{blok.component}</strong> has not been created yet.
    </p>
  );
};

export default DynamicComponent;
