import React from "react";
import DynamicComponent from "~components/dynamicComponent";
import { Accordion } from "react-bootstrap";
import { AccordionItemFull } from "~components/Accordion";
import Faq from "./style";

export default function AccordionBlock({ blok }) {
  return (
    <Faq.Accordion textColor={blok.text_color} accentColor={blok.accent_color}>

    <Accordion>
      {blok.block.map((item, index) => {
        return  <AccordionItemFull
        question={item.question}
        answer={item.answer}
        accKey={`${index}`}
      />;
      })}
    </Accordion>
    </Faq.Accordion>

  );
}
