import React from "react";
import Content from "./style";
import SbEditable from "storyblok-react";

export default function text_with_title({ blok, ...rest }) {
  return (
    <>
      <SbEditable content={blok}>
        <Content.Title as="h2">{blok.title}</Content.Title>
        <SbEditable content={blok}>
          <Content.Text>{blok.text}</Content.Text>{" "}
        </SbEditable>
      </SbEditable>
    </>
  );
}
