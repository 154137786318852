import React from "react";
import { Col } from "react-bootstrap";
import DynamicComponent from "~components/dynamicComponent";
import Hero from "./style";

export default function HeroText({ blok }) {
  return (
    <div>
      <Hero.Title
        as="h1"
        fontColor="#fff"
        dangerouslySetInnerHTML={{ __html: blok.title }}
      ></Hero.Title>
      <Hero.Text
        fontColor="#fff"
        dangerouslySetInnerHTML={{ __html: blok.text }}
      ></Hero.Text>
    </div>
  );
}
