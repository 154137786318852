import styled from "styled-components/macro";
import { Paragraph } from "~styled";

const StyledText = styled(Paragraph)`
  color: ${(props) => props.color || "black"};
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 25px;

  text-align: ${(props) => `${props.textAlign}` || ""};

  @media (min-width: 768px) {
    text-align: ${(props) => `${props.textAlignMd}` || ""};
  }

  @media (min-width: 1200px) {
    text-align: ${(props) => `${props.textAlignLg}` || ""};
  }


  @media (min-width: 575px) {
    font-size: 20px;
    line-height: 35px;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;


export default StyledText;
