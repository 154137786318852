import styled from "styled-components/macro";
import { Box, Heading, Button, Paragraph } from "~styled";

const Faq = styled(Box)`
  padding-top: 50px;
  padding-bottom: 40px;

  @media (min-width: 768px) {
    padding-top: 70px;
    padding-bottom: 60px;
  }
  @media (min-width: 992px) {
    padding-top: 105px;
    padding-bottom: 120px;
  }
  *:focus {
    outline: none;
  }
`;
Faq.Title = styled(Heading)`
  font-size: 32px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.3;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 38px;
  }

  @media (min-width: 992px) {
    font-size: 48px;
  }
`;
Faq.Body = styled(Box)``;
Faq.Accordion = styled(Box)`
  .accordion {
    &-item {
      border: none;
      margin-bottom: 20px;
      padding: 0 40px;
      .accordion-trigger {
        width: 100%;
        display: block;
        cursor: pointer;
        font-size: 25px;

        color: ${(props) => `${props.textColor}` || "black"};
        font-weight: 300 !important;
        letter-spacing: normal;
        padding: 0px 10px;
        font-weight: bold;
        line-height: 35px;
        padding-top: 12px;
        padding-bottom: 12px;
        position: relative;
        border-bottom: ${(props) => `1px solid ${props.textColor}` || "none"};
        &:before {
          content: "\f067";
          font-family: "Font Awesome 5 Free";
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          font-weight: 700;
          background-image: none;
          color: ${(props) => `${props.accentColor}` || "black"};
        }
      }
      &.active {
        .accordion-trigger:before {
          content: "\f068";
          font-family: "Font Awesome 5 Free";
          position: absolute;
          right: 0;
          font-size: 25px;
          top: 50%;
          transform: translateY(-50%);
          font-weight: 700;
          background-image: none;
          color: ${(props) => `${props.accentColor}` || "black"};

        }
      }
    }

    p {
      margin: 0;
      font-weight: 400;
      letter-spacing: normal;
      color: ${(props) => `${props.textColor}` || "black"};
      line-height: 2;
      color: rgba(38, 39, 41, 0.7);
      padding-bottom: 43px;
      padding: 10px 10px;
      font-size: 20px;
      line-height: 35px;
      position: relative;
      margin: 0;
    }
  }
`;

Faq.Box = styled(Box)``;
Faq.TabWrapper = styled(Box)``;
Faq.ButtonGroup = styled(Box)`
  p {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 32px;
    color: #262729;
    a {
      color: #28465e;
      &:hover {
        color: #28465e;
      }
    }
  }
`;

export default Faq;
