import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ReactTypingEffect from "react-typing-effect";
import { Link } from "~components";
import { Button } from "~styled";
import ImageGroup from "./Components/ImageGroup";
import Hero from "./style";

import { graphql } from "gatsby";
import SbEditable from "storyblok-react";

export const HeroSectionTypingEffect = ({ blok }) => {
  var words_array = [];

  for (var index in blok.words) {
    var element = blok.words[index];

    if (element["component"] == "typing_word") {
      words_array.push(element["word"]);
    }
  }

  return (
    <Hero className="position-relative bg-default">
      <Container>
        <Row>
          {/* Welcome content Area */}
          <Col
            className="col-xl-6 col-lg-7 col-md-8 col-xs-11 order-2 order-lg-1"
            xs="12"
          >
            <Hero.Content>
              <SbEditable content={blok}>
                <Hero.Title as="h1">
                  {blok.hero_title}
                  <br />
                  <ReactTypingEffect
                    text={words_array}
                    className="highlight-text d-inline-block text-primary"
                    speed="150"
                    eraseSpeed="100"
                    typingDelay="400"
                    eraseDelay="800"
                    cursorClassName="typed-cursor"
                  />
                </Hero.Title>
              </SbEditable>
              <SbEditable content={blok}>
                <Hero.Text>{blok.hero_text}</Hero.Text>
              </SbEditable>

              {/* Newsletter */}
              <Row css={{ display: blok.show_newsletter ? "flex" : "none" }}>
                <Col xs="12" className="col-xxl-10">
                  <Hero.Newsletter>
                    <form>
                      <input
                        type={"email"}
                        name={"email"}
                        placeholder="Enter your email"
                        className="form-control"
                      />
                      <Button
                        className="btn-purple-heart"
                        textTransform="capitalized"
                      >
                        Start For Free
                      </Button>
                    </form>
                  </Hero.Newsletter>
                  <Hero.NewsletterText>
                    By clicking the button, you are agreeing with our{" "}
                    <Link to="/">Terms & Conditions.</Link>
                  </Hero.NewsletterText>
                </Col>
              </Row>
            </Hero.Content>
          </Col>
          {/*/ .Welcome Content Area */}
          {/*Welcome Image Area */}
          <Col
            xs={12}
            className="col-xl-6 col-lg-5 col-md-10 order-1 order-lg-2 position-static"
          >
            <ImageGroup blok={blok.images} />
          </Col>
          {/*/ .Welcome Image Area */}
        </Row>
      </Container>
    </Hero>
  );
};
