import { StaticImage as Img } from "gatsby-plugin-image";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ServiceWidget from "./Component/Widget";
import Service from "./style";
import SbEditable from "storyblok-react";
import FluidImage from "../../components/FluidImage";

export const ServiceSection = ({ blok }) => {
  return (
    <>
      <Service className="border-top border-default-color-2 bg-default">
        <Service.Shape className="service-shape service-shape--l1">
          <FluidImage
            blok={blok}
            image={blok.image}
            alt="shape dot"
            layout="fullWidth"
            placeholder="blurred"
          />
        </Service.Shape>
        <Container>
          {/* Section Title */}
          <Row className="align-items-end justify-content-center text-start">
            <Col xs="12" className="col-lg-7 col-md-12 col-xs-10">
              <SbEditable content={blok}>
                <Service.Title as="h2" mb="0">
                  {blok.title}
                </Service.Title>
              </SbEditable>
            </Col>
            <Col xs="12" className="col-lg-5 col-md-12 col-xs-10">
              <SbEditable content={blok}>
                <Service.Text>{blok.text}</Service.Text>
              </SbEditable>
            </Col>
          </Row>
          <Service.Box mtLG="100px" mtMD="40px">
            <Row className="justify-content-center justify-content-md-start">
              {/* Single Service */}
              {blok.services.map((item) => {
                return (
                  <Col className="col-lg-4 col-xs-6 col-10">
                    <ServiceWidget blok={item} />
                  </Col>
                );
              })}
            </Row>
          </Service.Box>
        </Container>
      </Service>
    </>
  );
};
