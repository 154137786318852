import React, {Suspense} from "react";
import { StaticImage as Img } from "gatsby-plugin-image";
import { Col, Row } from "react-bootstrap";
import Hero from "./style";
import { Form, Link } from "~components";
import { Images } from "~data";
import DynamicComponent from "~components/dynamicComponent";
import FixedImage from "../../components/FixedImage";

export default function HeroImage({ blok }) {

  var height = blok.height;

  if (height == null){
    height="100";
  }
  const isSSR = typeof window === "undefined"

  return (
    <>
    <Hero height={height} bg="#28465e" style={{backgroundImage: `url(${blok.image.filename})`, backgroundSize: "cover", backgroundColor: "#28465e", backgroundPosition: `${blok.alignment}` }}>
    <div style={{width: "100vw", height: "100%", position: "absolute", top: "0", left: "0", backgroundSize: "cover", backgroundColor: "#282728", zIndex:"0", opacity: 0.4}}></div>

      <div className="top" css={{position: "relative"}}>
        {blok.block.map((item, index) => {
          return <DynamicComponent blok={item} key={item._uid} />;
        })}
      </div>
    </Hero>
    </>
  );
}
