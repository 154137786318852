import React from "react";
import { Col } from "react-bootstrap";
import DynamicComponent from "~components/dynamicComponent";

import FluidImage from "../../components/FluidImage";

import styled from 'styled-components'

const StyledFluidImage = styled.div`
.gatsby-image-wrapper{
  border-radius: ${(props) => `${props.borderRadius}px` || "0px"};
  max-height: ${(props) => `${props.smMax}px` || "unset"};

}

@media (min-width: 768px) {
  .gatsby-image-wrapper{
    max-height: ${(props) => `${props.mdMax}px` || "unset"};
  }

}



@media (min-width: 1200px) {
  .gatsby-image-wrapper{
    max-height: ${(props) => `${props.lgMax}px` || "unset"};
  }

}

`


export default function FluidImageBlock({ blok }) {
  return <StyledFluidImage borderRadius={blok.border_radius} smMax={blok.small_max_height} mdMax={blok.medium_max_height} lgMax={blok.large_max_height}><FluidImage blok={blok} image={blok.image} /></StyledFluidImage>;
}
