import React from "react";

import SbEditable from "storyblok-react";
import Img from "gatsby-image";

import { getFluidGatsbyImage } from "gatsby-storyblok-image";

const FluidImage = ({ image, blok, maxWidth="600" }) => {
  const fluidProps = getFluidGatsbyImage(image.filename, {
    maxWidth: maxWidth,
    quality: "75",
    toFormat: "jpg",
    useBase64: 'true',
    base64: 'true'
  });

  return (
    <SbEditable content={blok}>
      <Img fluid={fluidProps} alt={image.alt} useBase64 base64 draggable={false} placeholderClassName={`${true ? "placeholder-img": ""}`} fadeIn={true}/>
    </SbEditable>
  );
};

export default FluidImage;
