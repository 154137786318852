import React from "react";
import { Container } from "react-bootstrap";
import DynamicComponent from "~components/dynamicComponent";

import styled from 'styled-components'

const StyledContainer = styled(Container)`

`

export default function ContainerBlock({ blok }) {
  return (
    <StyledContainer>
      {blok.block.map((item, index) => {
        return <DynamicComponent blok={item} key={item._uid} />;
      })}
    </StyledContainer>
  );
}
