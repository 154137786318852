import React from "react";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import DynamicComponent from "~components/dynamicComponent";
import Feature from "./style";
import TabNavWidget from "./Component/TabNavWidget";
import ButtonBlock from "../Button/Block";



export default function MultiSelector({ blok }) {
  return (
      <Tab.Container defaultActiveKey={0}>
      <Feature.TabNavWrapper as={Nav} css={{alignItems: "center"}}>
            {blok.tabs.map((item, index) => {
              return (
                <Nav.Link
                  eventKey={index}
                  className="nav-item selector-blok"
                >
                          <DynamicComponent
                            blok={item.block[0]}
                            key={item.block[0]._uid}
                          />
          
</Nav.Link>
              );
            })}
        </Feature.TabNavWrapper>


      <Tab.Content className="tab-content tab-content--feature">
      {blok.sections.map((item, index) => {
        return(
          <Tab.Pane eventKey={index}>
                          <DynamicComponent
                            blok={item.section[0]}
                            key={item.section[0]._uid}
                          />
              </Tab.Pane>

        );
      })}
              </Tab.Content>
      </Tab.Container>
  );
}
