import React, { useRef } from "react";
import Slider from "react-slick";

import { Row } from "react-bootstrap";
import DynamicComponent from "~components/dynamicComponent";
import Testimonial from "./style";

export default function SliderBlock({ blok }) {
  const elSlider = useRef();


  const sliderConfig1 = {
    dots: false,
    infinite: true,
    speed: 900,
    slidesToShow: parseInt(blok.sm_show),
    slidesToScroll: parseInt(blok.sm_scroll),
    arrows: false,
    centerPadding: false,
    autoPlay: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: parseInt(blok.lg_show),
          slidesToScroll: parseInt(blok.lg_scroll),
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: parseInt(blok.md_show),
          slidesToScroll: parseInt(blok.md_scroll),
        },
      },
    ],
  };


  return (
    <div>


      <Row>
      <Slider
          ref={elSlider}
          className="testimonial-slider row justify-content-center"
          {...sliderConfig1}
        >
                {blok.block.map((item, index) => {
        return <DynamicComponent blok={item} key={item._uid} />;
          })}

        </Slider>

      </Row>

      <Row>
      <Testimonial.SliderButton>
              <Testimonial.Button
                className="slick-arrow"
                onClick={() => {
                  elSlider.current.slickPrev();
                }}
              >
                <i className="fas fa-arrow-left"></i>
              </Testimonial.Button>
              <Testimonial.Button
                className="slick-arrow"

                onClick={() => {
                  elSlider.current.slickNext();
                }}
              >
                <i className="fas fa-arrow-right"></i>
              </Testimonial.Button>
            </Testimonial.SliderButton>
      </Row>
      

    </div>
  );
}
