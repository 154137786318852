import React from "react";
import { Row } from "react-bootstrap";
import DynamicComponent from "~components/dynamicComponent";

import styled from 'styled-components'

const StyledRow = styled(Row)`
justify-content: center;

flex-direction: ${(props) => `${props.flexSm}` || "unset"};

@media (min-width: 768px) {
    flex-direction: ${(props) => `${props.flexMd}` || "unset"};
}



@media (min-width: 1200px) {
    flex-direction: ${(props) => `${props.flexLg}` || "unset"};
}

`



export default function RowBlock({ blok }) {

  const flex_sm = blok.flex_sm ?? "inherit"
  const flex_md = blok.flex_md ?? "inherit"
  const flex_lg = blok.flex_lg ?? "inherit"

  return (
    <StyledRow
      xs={blok.xs}
      sm={blok.sm}
      md={blok.md}
      lg={blok.lg}
      xl={blok.xl}
      noGutters={blok.no_gutters}
      flexSm={flex_sm}
      flexMd={flex_md}
      flexLg={flex_lg}
    >
      {blok.block.map((item, index) => {
        return <DynamicComponent blok={item} key={item._uid} />;
      })}
    </StyledRow>
  );
}
