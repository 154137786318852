import React from "react";
import { ButtonLink, Button } from "~styled";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import StyledText from "../Text/style";
import Contact from "../../sections/contact/ContactOne/ContactSection/style";

import instagram from '../../assets/icons/ca-insta.png'
import facebook from '../../assets/icons/ca-facebook.png'
import linked_in from '../../assets/icons/ca-linkedin.png'


import email from '../../assets/icons/ca-mail.png'
import phone from '../../assets/icons/ca-phone.png'
import location from '../../assets/icons/ca-location.png'

export default function ContactFormBlock({ blok }) {

  return (
    <Row css={{borderRadius: "25px", overflow: "hidden"}}>
      <Col lg={8} css={{backgroundColor: "#FAFAFA", padding: "1.5rem"}}>
        <StyledText css={{fontWeight: "bold" }}>Send us a message</StyledText>
        <form name="contact" method="POST" netlify-honeypot="bot-field" data-netlify="true" action="/pages/success">
                <Row>
                <input name="bot-field" css={{display: "none"}}/>
                <input type="hidden" name="form-name" value="contact" css={{display: "none"}} />

                <Col xs="12" className="col-lg-6 mb-4">
                    <div className="form-floating">
                      <input
                        className="form-control"
                        name="name"
                        placeholder="Leave a comment here"
                        id="floatinginput"
                      />
                      <label htmlFor="floatinginput">Full Name</label>
                    </div>
                  </Col>
                  <Col xs="12" className="col-lg-6 mb-4">
                    <div className="form-floating">
                      <input
                        className="form-control"
                        name="phone"
                        placeholder="Leave a comment here"
                        id="floatinginput2"
                      />
                      <label htmlFor="floatinginput2">Phone number</label>
                    </div>
                  </Col>

                  <Col xs="12" className="col-lg-6 mb-4">
                    <div className="form-floating">
                      <input
                        className="form-control"
                        name="email"
                        placeholder="Leave a comment here"
                        id="floatinginput"
                      />
                      <label htmlFor="floatinginput">Your Email</label>
                    </div>
                  </Col>
                  <Col xs="12" className="col-lg-6 mb-4">
                    <div className="form-floating">
                      <input
                        className="form-control"
                        name="reason"
                        placeholder="Leave a comment here"
                        id="floatinginput2"
                      />
                      <label htmlFor="floatinginput2">Reason</label>
                    </div>
                  </Col>
                  <Col xs="12" className="col-lg-12 mb-4">
                    <div className="form-floating">
                      <input
                        className="form-control"
                        name="subject"
                        placeholder="Leave a comment here"
                        id="floatinginput2"
                      />
                      <label htmlFor="floatinginput2">Subject</label>
                    </div>
                  </Col>

                  <Col xs="12" className="col-lg-12">
                    <div className="form-floating">
                      <textarea
                        className="form-control"
                        name="message"
                        placeholder="Leave a comment here"
                        id="floatingTextarea3"
                        css={{minHeight: "275px !important"}}
                      />
                      <label htmlFor="floatingTextarea3">
                        Your Message Here{" "}
                      </label>
                    </div>
                  </Col>
                  <Col xs="12" className="col-lg-12">
                    <Row className="align-items-center mt-3">
                      <Col
                        xs="12"
                        className="col-md-4 col-lg-5 col-xl-4 pt-3"
                      >
                        <Contact.Button>Send Message</Contact.Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </form>

      </Col>
      <Col lg={4} css={{backgroundColor: "#28465E", padding: "1.5rem"}}>
      <StyledText css={{fontWeight: "bold", color: "white" }}>Contact Information</StyledText>
      <div css={{display: "flex", maxWidth: "100%"}} className='mb-4'>
        <img src={email} css={{objectFit: "none"}} height="32px"></img>
        <a href="mailto:Careaidaustralia@gmail.com"><StyledText css={{color: "white", paddingLeft: "15px"}}>careaidaustralia@gmail.com</StyledText></a>
      </div>
      <div css={{display: "flex", maxWidth: "100%"}} className='mb-4'>
        <img src={phone} css={{objectFit: "none"}} height="32px"></img>
        <a href="tel:+61 433 401 659"><StyledText css={{color: "white", paddingLeft: "15px"}}>+61 433 401 659</StyledText></a>

      </div>



      <div css={{display: "flex", justifyContent: "space-between", maxWidth: "45%", paddingTop: "2rem", paddingBottom: "2rem"}} className='mb-4'>
        <a href="https://www.facebook.com/profile.php?id=100080935804979" target="_blank"><img src={facebook} css={{objectFit: "none"}} height="32px"></img></a>

      </div>
      <StyledText css={{fontWeight: "bold", color: "white" }}>Make a difference and get started caring for Australians in need today.</StyledText>

      </Col>
    </Row>
  );
}
