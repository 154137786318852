import React from "react";
import { Col } from "react-bootstrap";
import DynamicComponent from "~components/dynamicComponent";
import styled from 'styled-components'

const StyledBreakpoint = styled.div`

display: ${(props) => `${props.sm}` || "unset"};

@media (min-width: 768px) {
  display: ${(props) => `${props.md}` || "unset"};
}



@media (min-width: 1200px) {
  display: ${(props) => `${props.lg}` || "unset"};
}

`

export default function BreakpointBlock({ blok }) {
  return (
    <StyledBreakpoint
      sm={blok.sm}
      md={blok.md}
      lg={blok.lg}
    >
      {blok.block.map((item, index) => {
        return <DynamicComponent blok={item} key={item._uid} />;
      })}
    </StyledBreakpoint>
  );
}
