import React, { useState, Suspense } from "react";
import Hero from "./style";
import { Form, Link } from "~components";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { Spinner } from "react-bootstrap";

import styled from "styled-components/macro";

export default function Locator({ blok }) {
  const [location, setLocation] = useState("");
  const isSSR = typeof window === "undefined"

  return (
    <>
    <Hero.Newsletter>

      <Form>
        <Hero.Box>
          <i className="fas fa-search icon" />
          {!isSSR && (
          <Suspense fallback={<Spinner animation="border" role="status" className='loading'><span className="visually-hidden">Loading...</span></Spinner>}>
          <GooglePlacesAutocomplete apiKey="AIzaSyAaap3I7wYaDquUD8ByFPTS6m9c-WuXoDo" 
                apiOptions={{ language: 'en', region: 'au' }} 
                selectProps={{
                placeholder: "Search for support workers",
                location, 
                noOptionsMessage: () => "Search suburb / postcode",
                onChange: setLocation,
                styles:{
                  container: (provided) => ({
                    ...provided,
                    position: "absolute",
                    width: "95%",
                    paddingTop: "10px",
                    paddingLeft: "55px",
                    borderWidth: "0px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }),
                  input: (provided) => ({
                    ...provided,
                    borderWidth: "0px",

                  }),
                  singleValue: (provided) => ({
                    ...provided,
                  }),
                  menu: (provided) => ({
                    ...provided,
                    width: "80%",
                  }),
                  control: (provided) => ({
                    ...provided,
                    borderWidth: "0px",
                    boxShadow: "unset",

                  }),                  
                  
                  valueContainer: (provided) => ({
                    ...provided,
                    display: "flex",
                    alignItems: "center"

                  }),




                }
                }}
          />
                </Suspense>
                )}


          <Form.Input placeholder="Search for support workers" />
        </Hero.Box>
        <Link to={`/search?location=${location.label}`}>
        <Hero.NewsletterButton rounded={true} className="btn-torch-red">
          Go!
        </Hero.NewsletterButton>

        </Link>
      </Form>

    </Hero.Newsletter>
    </>
  );

}
