import React from "react";
import StyledText from "./style";

export default function TextBlock({ blok }) {
  return (
    <StyledText
      as="p"
      dangerouslySetInnerHTML={{ __html: blok.text }}
      color={blok.color}
      textAlign={blok.alignment}
      textAlignMd={blok.md_alignment}
      textAlignLg={blok.lg_alignment}
      style={{fontWeight: blok.font_weight}}
    ></StyledText>
  );
}
