import React from "react";
import DynamicComponent from "~components/dynamicComponent";

export default function FlexBlock({ blok }) {
  return (
    <div style={{display: "flex", minHeight: "100%", minWidth: "100%", flexDirection: blok.flexDirection, flexWrap: blok.flexWrap, alignContent: blok.alignContent, justifyContent: blok.justifyContent, alignItems: blok.alignItems}}>
      {blok.block.map((item, index) => {
        return <DynamicComponent blok={item} key={item._uid} />;
      })}
    </div>
  );
}
