import styled from "styled-components/macro";
import { Heading } from "~styled";

const Title = styled(Heading)`
  color: ${(props) => props.color || "black"};
  font-size: 20px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 25px;
  position: relative;
  @media (min-width: 768px) {
    font-size: 38px;
    line-height: 45px;
  }

  @media (min-width: 992px) {
    font-size: 45px;
    line-height: 55px;
  }

  text-align: ${(props) => `${props.textAlign}` || ""};

  @media (min-width: 768px) {
    text-align: ${(props) => `${props.textAlignMd}` || ""};
  }

  @media (min-width: 1200px) {
    text-align: ${(props) => `${props.textAlignLg}` || ""};
  }


  
`;


Title.hero = styled(Heading)`
  color: ${(props) => props.color || "black"};
  font-size: 45px;
  line-height: 50px;
  font-weight: 500;
  letter-spacing: normal;
  position: relative;
  @media (min-width: 768px) {
    font-size: 60px;
    line-height: 70px;
  }

  @media (min-width: 992px) {
    font-size: 75px;
    line-height: 90px;
  }

  text-align: ${(props) => `${props.textAlign}` || ""};

  @media (min-width: 768px) {
    text-align: ${(props) => `${props.textAlignMd}` || ""};
  }

  @media (min-width: 1200px) {
    text-align: ${(props) => `${props.textAlignMd}` || ""};
  }


  
`;


export default Title
