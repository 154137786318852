import React from "react";
import Title from "./style";

export default function TitleBlock({ blok }) {

  if(blok.variant == "hero"){
    return (
      <Title.hero
        as="h1"
        dangerouslySetInnerHTML={{ __html: blok.title }}
        color={blok.color}
        textAlign={blok.alignment}
        textAlignMd={blok.md_alignment}
        textAlignLg={blok.lg_alignment}
        style={{fontWeight: blok.font_weight}}
      ></Title.hero>
    );
  
  }else{
    return (
      <Title
        as="h2"
        dangerouslySetInnerHTML={{ __html: blok.title }}
        color={blok.color}
        textAlign={blok.alignment}
        textAlignMd={blok.md_alignment}
        textAlignLg={blok.lg_alignment}  
        style={{fontWeight: blok.font_weight}}
      ></Title>
    );  
  }
}
